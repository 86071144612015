import React, { FunctionComponent } from "react";
import "./LabeledText.scss";

interface Props {
  label: string;
}
export const LabeledText: FunctionComponent<Props> = ({ label, children }) => {
  return (
    <div className="labeled-text">
      <span className="label">{label}</span>
      <span className="text">{children}</span>
    </div>
  );
};
