import React, { VoidFunctionComponent } from "react";
import countys from "../data/countys.json";

interface Props {
  countyId: number;
}

export const CountyName: VoidFunctionComponent<Props> = ({ countyId }) => {
  const county = countys.find((county) => county.countyId === countyId);
  return <React.Fragment>{county?.name ?? "Okänt län"}</React.Fragment>;
};
